const patterns = {
  native: {
    ios: /makuake-ios$/i,
    android: /makuake-android$/i,
  },
  browser: {
    ios: /iphone|ipod/i,
    android: /android/i,
    mobile:
      /iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone|IEMobile|dream|CUPCAKE|webOS|incognito|webmate/i,
    ie: /trident|msie/i,
  },
};

// NOTE: UserAgent廃止後に備えて、UA-CHによる判定も行っている
class UserAgent {
  /**
   * @param {string=} ua
   */
  constructor(ua) {
    this.ua = typeof ua === 'string' ? ua : navigator.userAgent;
    this.uaCh = {};
  }

  /**
   * UA-CHで一部の情報を取得する。他メソッドを利用する前に実行を推奨。
   * @param {object} uaCh 単体テスト用の引数 通常は使用しない
   */
  async init(uaCh) {
    if (uaCh) {
      this.uaCh = uaCh;
    }

    if (typeof navigator.userAgentData === 'undefined') {
      return;
    }

    const { platform, platformVersion } =
      await navigator.userAgentData.getHighEntropyValues([
        'platform',
        'platformVersion',
      ]);
    this.uaCh = {
      platform,
      platformVersion,
      mobile: navigator.userAgentData.mobile,
    };
  }

  /** @returns {boolean} */
  isNative() {
    return Object.values(patterns.native).some(matcher =>
      matcher.test(this.ua),
    );
  }

  /** @returns {boolean} */
  isIOSNative() {
    return patterns.native.ios.test(this.ua);
  }

  /** @returns {boolean} */
  isIOS() {
    const isIOSByUa = Object.values(patterns).some(pattern => {
      const matcher = pattern.ios;
      return matcher instanceof RegExp && matcher.test(this.ua);
    });
    return isIOSByUa || this.uaCh.platform === 'iOS';
  }

  /** @returns {boolean} */
  isAndroid() {
    const isAndroidByUa = Object.values(patterns).some(pattern => {
      const matcher = pattern.android;
      return matcher instanceof RegExp && matcher.test(this.ua);
    });
    return isAndroidByUa || this.uaCh.platform === 'Android';
  }

  /** @returns {boolean} */
  isSP() {
    return (
      this.isIOS() ||
      this.isAndroid() ||
      patterns.browser.mobile.test(this.ua) ||
      !!this.uaCh.mobile
    );
  }

  /** @returns {boolean} */
  isSPBrowser() {
    return this.isSP() && !this.isNative();
  }

  /** @returns {boolean} */
  isPC() {
    return !this.isSP();
  }

  /** @returns {boolean} */
  isIE() {
    return Object.values(patterns).some(pattern => {
      const matcher = pattern.ie;
      return matcher instanceof RegExp && matcher.test(this.ua);
    });
  }

  /** @returns {boolean} */
  isIosVersionLessThanTwelve() {
    const uaMatches = this.ua.toLowerCase().match(/iphone os (\d*)/);
    if (uaMatches) {
      return parseFloat(uaMatches[1]) < 12;
    }

    return (
      this.uaCh.platform === 'iOS' && parseFloat(this.uaCh.platformVersion) < 12
    );
  }

  /**
   * @returns {string} UA String
   */
  toString() {
    return this.ua ? this.ua : '';
  }
}

export default UserAgent;
