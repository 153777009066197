<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

const { fetchNewProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() =>
  fetchNewProjects({
    perPage: PROJECT_DISPLAY_NUMBER * 5,
    random: PROJECT_DISPLAY_NUMBER,
  }),
);
</script>

<template>
  <ProjectSectionBase
    href="/discover/new"
    label="new"
    :loading
    :projects
    title="新着のプロジェクト"
  />
</template>
