import { SECTION_TITLE as RECOMMEND_SECTION_TITLE } from '@/consts/recommend';

const GA_EVENTS = Object.freeze({
  FETCH_ALL_PROJECTS: {
    ID: 'projects_all',
    NAME: 'すべてのプロジェクト',
  },
  FETCH_COMING_SOON_PROJECTS: {
    ID: 'projects_coming_soon',
    NAME: 'もうすぐ開始',
  },
  FETCH_ENDING_SOON_PROJECTS: {
    ID: 'projects_ending_soon',
    NAME: 'もうすぐ終了',
  },
  FETCH_GOVERNMENT_PROJECTS: {
    ID: 'projects_government',
    NAME: 'ふるさと納税型',
  },
  FETCH_LIKED_PROJECTS: {
    ID: 'projects_favorite',
    NAME: '気になる',
  },
  FETCH_MOST_FUNDED_PROJECTS: {
    ID: 'projects_all_time_ranking',
    NAME: '歴代ランキング',
  },
  FETCH_MY_TAG_PROJECTS: {
    ID: 'projects_my_tag',
    NAME: 'Myタグ',
  },
  FETCH_NEW_PROJECTS: {
    ID: 'projects_new',
    NAME: '新着のプロジェクト',
  },
  FETCH_PICKUP_PROJECTS: {
    ID: 'projects_pickup',
    NAME: '今日のピックアップ',
  },
  FETCH_POPULAR_PROJECTS: {
    ID: 'projects_popular',
    NAME: '人気のプロジェクト',
  },
  FETCH_PROJECTS_BY_CATEGORY_SLUG: {
    ID: 'projects_category',
    NAME: 'カテゴリー別プロジェクト',
  },
  FETCH_PROJECTS_BY_KEYWORD: {
    ID: 'projects_keyword',
    NAME: 'キーワード検索結果',
  },
  FETCH_PROJECTS_BY_LOCATION_IDS: {
    ID: 'projects_location',
    NAME: '地域別プロジェクト',
  },
  FETCH_PROJECTS_BY_TAG_IDS: {
    ID: 'projects_tag',
    NAME: 'タグ別プロジェクト',
  },
  FETCH_RANKED_PROJECTS: {
    ID: 'projects_ranking',
    NAME: '今日のランキング',
  },
  FETCH_RECOMMENDED_PROJECTS: {
    ID: 'projects_recommended',
    NAME: RECOMMEND_SECTION_TITLE,
  },
  FETCH_REMINDED_PROJECTS: {
    ID: 'projects_reminded',
    NAME: '応援購入忘れはありませんか',
  },
  FETCH_SELECTED_OWNERS_PROJECTS: {
    ID: 'projects_selected_owners',
    NAME: '推奨実行者',
  },
  FETCH_SIMILAR_PROJECTS: {
    ID: 'projects_similar',
    NAME: '同じタグのプロジェクト',
  },
  FETCH_WATCHED_PROJECTS: {
    ID: 'projects_history',
    NAME: '閲覧履歴',
  },
});

export default GA_EVENTS;
