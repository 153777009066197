$(function() {
  notification();
  suggest();
  logout_with_braze();

  function logout_with_braze() {
    var logoutLink = $('a[href^="/logout"]');
    logoutLink.on('click', function(e) {
      e.preventDefault();

      if (window.braze) {
        //Brazeのログアウト処理
        braze.wipeData();
      }

      location.href = logoutLink.attr('href');
    })
  };
});

function notification() {
  var mymenuBox = $('.link03After');

  // マイページメニュー
  mymenuBox.click(function(event) {
    event.stopPropagation();
    $('#myMenuBox').toggle();

    // 要素の外側クリック感知
    $(document).on('click', function(event) {
      // マイページメニュー
      if (!$(event.target).closest('.link03After').length) {
        $('#myMenuBox').hide();
      }
    });

    let action = $('#myMenuBox').is(':visible') ? 'open' : 'close';
    window.Geenie?.ClickTracking?.sendClickEvent?.(`header_account_${action}`);
  });
}

var suggestListClick = false;
function suggest() {
  var timer = false;
  $('#keyword').on('input', function(e) {
    // 入力時の処理

    if (timer) {
      clearTimeout(timer);
    }
    var value = $(this).val();

    timer = setTimeout(function() {
      if (!value) {
        $('#suggest').css('display', 'none');
        return; // 空文字の時は通信しない
      }
      $.ajax({
        url: '/api/search/suggest/',
        type: 'GET',
        data: {
          'keyword': value
        }
      }).done(function(data) {
        var html = '';
        html += '<ul>';
        if (data && data['results'].length > 0) {
          for (var i = 0; i < data['results'].length; i++) {
            html += '<li><a class="suggest-link" href="/project/';
            html += data['results'][i]['code'];
            html += '?from=suggest&keyword=' + value + '&disp_order=' + (i + 1);
            html += '">';
            html += data['results'][i]['title'];
            html += '</a></li>';
          }
          html += '</ul><button type="submit" class="suggest-button">検索結果をもっと見る</button>';
          $('#suggest').html(html);
          $('#suggest').css('display', 'block');
          $('#suggest').css('z-index', 9999);
        }
      });
    }, 250);
  });
  $('#keyword').on('blur', function(e) {
    e.preventDefault();
    if (suggestListClick) {
      suggestListClick = false;
      return;
    }
    $('#suggest').css('display', 'none');
  });
  $('div#suggest').on('mousedown', 'li', function() {
    suggestListClick = true;
  });
  $('div#suggest').on('mousedown', 'button', function() {
    suggestListClick = true;
  });
}
