import { computed, ref } from 'vue';
import useSnackbar from '@/composables/common/snackbar';
import { DEFAULT_ERROR_MESSAGE } from '@/consts/error';
import phpApi from '@/modules/api/php/tag';
import recommendedTagsApi from '@/modules/api/v2/recommended-tags';
import v2Api from '@/modules/api/v2/tag';
import watchedProjects from '@/modules/watchedProjects';

const maxWatchedIdCount = 5;

const popularTags = [
  { id: 203, name: '便利グッズ' },
  { id: 8, name: 'ガジェット' },
  { id: 121, name: 'レストラン' },
  { id: 180, name: '会員制' },
  { id: 66, name: 'フード' },
  { id: 60, name: 'グルメ' },
  { id: 158, name: '日本酒' },
  { id: 77, name: '肉' },
  { id: 175, name: 'ギフト' },
  { id: 81, name: 'テクノロジー' },
  { id: 184, name: '日本製' },
  { id: 129, name: '地域活性' },
  { id: 183, name: '伝統' },
  { id: 115, name: '文房具' },
  { id: 50, name: 'ファッション' },
  { id: 57, name: '腕時計' },
  { id: 53, name: '財布' },
  { id: 52, name: 'リュック' },
  { id: 134, name: 'アウトドア' },
  { id: 109, name: '子ども' },
];

const tags = ref([]);
const layerTags = ref({});
const isLoaded = ref(false);
let initLayerPromise = null;

export default function useTag() {
  const recommendedTags = ref([]);
  const isLoading = ref(false);

  const { showError } = useSnackbar();

  const fetchTags = async () => {
    if (isLoading.value) return;
    isLoading.value = true;

    try {
      const { data } = await phpApi.fetchTags();
      tags.value = data.tags?.length ? data.tags : [];
      isLoaded.value = true;
    } catch (e) {
      showError({ message: DEFAULT_ERROR_MESSAGE });
    } finally {
      isLoading.value = false;
    }
  };

  const fetchLayerTags = () => {
    // 初期化が未実行の場合のみ実行
    if (!initLayerPromise) {
      initLayerPromise = (async () => {
        if (isLoading.value) return;
        isLoading.value = true;

        try {
          const { data } = await v2Api.fetchLayer();
          layerTags.value = data;
          isLoaded.value = true;
        } catch (e) {
          showError({ message: DEFAULT_ERROR_MESSAGE });
        } finally {
          isLoading.value = false;
        }
      })();
    }

    return initLayerPromise; // 初期化プロミスを返す
  };

  const topTags = computed(
    () =>
      layerTags.value?.top_themes?.map(top => ({
        id: top.top_theme_tag_id,
        name: top.top_theme_tag_name,
      })) || [],
  );

  const secondTags = computed(
    () =>
      layerTags.value?.top_themes?.flatMap(
        top =>
          top.second_themes?.map(second => ({
            id: second.second_theme_tag_id,
            name: second.second_theme_tag_name,
          })) || [],
      ) || [],
  );

  const thirdTags = computed(
    () =>
      layerTags.value?.top_themes?.flatMap(
        top =>
          top.second_themes?.flatMap(
            second =>
              second.tags?.map(third => ({
                id: third.tag_id,
                name: third.tag_name,
              })) || [],
          ) || [],
      ) || [],
  );

  const genreTags = computed(
    () =>
      layerTags.value?.genre_themes?.flatMap(
        theme =>
          theme.genre_tags?.map(genre => ({
            id: genre.tag_id,
            name: genre.tag_name,
          })) || [],
      ) || [],
  );

  const fetchRecommendedTags = async () => {
    const watchedIds = watchedProjects.listIds().slice(0, maxWatchedIdCount);
    if (!watchedIds.length) return;

    if (isLoading.value) return;
    isLoading.value = true;

    try {
      const { data } = await recommendedTagsApi.fetchRecommendedTags({
        project_ids: watchedIds,
      });

      recommendedTags.value = data?.tags?.length
        ? data.tags.map(tag => ({
            id: tag.id,
            title: `# ${tag.name}`,
          }))
        : [];
    } catch (e) {
      showError({ message: DEFAULT_ERROR_MESSAGE });
    } finally {
      isLoading.value = false;
    }
  };

  /**
   * 中タグ、小タグが所属する大タグを取得する
   * @param {number} tagId
   * @returns {number|null} 該当する大タグ
   */
  const retrieveTopTagId = tagId => {
    const topTag = layerTags.value?.top_themes?.find(topTheme => {
      if (topTheme.top_theme_tag_id === tagId) {
        return true;
      }
      return topTheme.second_themes.find(secondTheme => {
        if (secondTheme.second_theme_tag_id === tagId) {
          return true;
        }
        return secondTheme.tags.find(tag => tag.tag_id === tagId);
      });
    });

    return topTag ? topTag.top_theme_tag_id : null;
  };

  return {
    fetchLayerTags,
    fetchTags,
    genreTags,
    fetchRecommendedTags,
    isLoaded,
    isLoading,
    layerTags,
    popularTags,
    secondTags,
    recommendedTags,
    retrieveTopTagId,
    tags,
    thirdTags,
    topTags,
  };
}
