import Cookie from 'js-cookie';
import client from '@/modules/api/createClient';
import csrfApi from '@/modules/api/php/csrf';
import qs from 'qs';

export const FUEL_CSRF_TOKEN = 'fuel_csrf_token';
export const RECAPTCHA_V3_SITE_KEY = '6LeOh7UaAAAAAIWFWASjhtqodEVuBhuPYGpU_wBG';

const securePost = async (url, data = {}, config = {}) => {
  await csrfApi.fetchCsrf();
  const csrfToken = Cookie.get(FUEL_CSRF_TOKEN);

  return client.phpApiClient.post(
    url,
    qs.stringify({ ...data, fuel_csrf_token: csrfToken }),
    config,
  );
};
const securePostJson = async (url, data = {}, config = {}) => {
  await csrfApi.fetchCsrf();
  const csrfToken = Cookie.get(FUEL_CSRF_TOKEN);

  return client.phpApiClient.post(
    url,
    { ...data, fuel_csrf_token: csrfToken },
    config,
  );
};

export default {
  securePost,
  securePostJson,
};
