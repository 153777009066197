<script setup>
import { computed, onBeforeMount, ref, toValue, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  MButton,
  MCheckbox,
  MIcon,
  MText,
} from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import {
  FILTER_IN_STORE,
  FILTER_ONGOING,
  SORT_OPTIONS,
} from '@/consts/discover';

const props = defineProps({
  /** STORE販売中フィルターの初期値
   *
   * `in_store` クエリストリングが付与されていない場合にこの値を参照する
   */
  isInStore: { type: Boolean, default: true },
  /** 実施中フィルターの初期値
   *
   * `ongoing` クエリストリングが付与されていない場合にこの値を参照する
   */
  isOngoing: { type: Boolean, default: true },
  loading: { type: Boolean, default: false },
  /**
   * @typedef {Object} SortOption
   * @property {string} key
   * @property {string} label
   */
  /**
   * `NO_SORT_OPTIONS`を渡すとソートボタンが非表示になる
   * @type {ReadonlyArray<SortOption>}
   */
  sortOptions: {
    type: Array,
    default: SORT_OPTIONS,
  },
});

const emit = defineEmits(['fetch']);

const filter = defineModel('filter', { type: Object, required: true });

const route = useRoute();
const router = useRouter();
const { sendClickEvent } = useGaEvent();

const sortIndex = computed(() =>
  Math.max(
    props.sortOptions.findIndex(
      opt =>
        opt.key === (route.query.sort ? route.query.sort.toLowerCase() : ''),
    ),
    0,
  ),
);
const sortLabel = computed(() => props.sortOptions[sortIndex.value].label);

const toBoolean = str => str && str.toLowerCase() === 'true';

/**
 * フィルターの状態をクエリストリングに合わせて更新
 * @param {Object} query
 */
const updateFilter = query => {
  filter.value.isOngoing = !query.ongoing
    ? toValue(props.isOngoing)
    : toBoolean(query.ongoing);
  filter.value.isInStore = !query.in_store
    ? toValue(props.isInStore)
    : toBoolean(query.in_store);
  filter.value.sort = query.sort ? query.sort.toLowerCase() : '';
};

const deepEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

watch(
  () => ({ params: route.params, query: route.query }),
  (newValue, oldValue) => {
    if (deepEqual(newValue, oldValue)) return;
    updateFilter(newValue.query);
    emit('fetch');
  },
  { deep: true },
);

/**  URLのクエリストリングを更新 */
const updateQueryStr = () => {
  const query = {
    ...route.query,
    ongoing: filter.value.isOngoing,
    in_store: filter.value.isInStore,
  };
  if (filter.value.sort) query.sort = filter.value.sort.toLowerCase();
  router.replace({ query });
};

const onChange = (value, index) => {
  sendClickEvent(`filter_${value ? 'on' : 'off'}`, index);
  updateQueryStr();
};

const handleClick = () => {
  let nextIndex = sortIndex.value + 1;
  if (props.sortOptions.length <= nextIndex) {
    nextIndex = 0;
  }
  filter.value.sort = props.sortOptions[nextIndex].key;

  sendClickEvent('sort', nextIndex);
  updateQueryStr();
};

/** アクション実行時の最低待機時間中か */
const waiting = ref(false);

/** ローディング開始と並行して最低待機時間のタイマーを走らせる */
watch(
  () => props.loading,
  newValue => {
    if (newValue) {
      waiting.value = true;
      setTimeout(() => {
        waiting.value = false;
      }, 300);
    }
  },
);

onBeforeMount(() => {
  updateFilter(route.query);
});
</script>

<template>
  <div class="project-filter flex justify-between mb-4 sm:mb-6">
    <div class="checkboxes flex flex-wrap gap-x-4 gap-y-2 items-center mr-6">
      <MCheckbox
        v-model="filter.isOngoing"
        :readonly="loading || waiting"
        :value="FILTER_ONGOING"
        @change="onChange(filter.isOngoing, 0)"
      >
        <MText class="block" size="x-small" sm="medium" tag="span"
          >実施中</MText
        >
      </MCheckbox>
      <MCheckbox
        v-model="filter.isInStore"
        :readonly="loading || waiting"
        :value="FILTER_IN_STORE"
        @change="onChange(filter.isInStore, 1)"
      >
        <MText class="block" size="x-small" sm="medium" tag="span"
          >STORE販売中</MText
        >
      </MCheckbox>
    </div>

    <MButton
      v-if="sortOptions.length"
      class="sort-button flex flex-col items-center justify-center shrink-0 sm:flex-row sm:-my-2 sm:-mx-2"
      :readonly="loading || waiting"
      size="small"
      variant="plain"
      @click="handleClick"
    >
      <template #label>
        <MIcon
          class="mb-1 sm:mb-0 sm:mr-1"
          name="transferVertical"
          size="16"
          sm="24"
        />
        <MText color="current" size="3x-small" sm="medium">{{
          sortLabel
        }}</MText>
      </template>
    </MButton>
  </div>
</template>

<style scoped>
/* MButtonのCSSを上書き */
.project-filter .sort-button {
  min-width: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>
