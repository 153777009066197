export const TAGS = {
  MADE_IN_JAPAN: {
    id: 184,
    title: '日本のものづくりを応援',
  },
  RESTAURANT: {
    id: 121,
    title: 'ストーリーのある飲食体験',
  },
  UTILITY_GOODS: {
    id: 203,
    title: '生活にワクワクを',
  },
  GADGET: {
    id: 8,
    title: 'ガジェット大好き',
  },
  FASHION: {
    id: 50,
    title: 'こだわりのファッションを',
  },
  OUTDOOR: {
    id: 134,
    title: 'アウトドアを楽しむ',
  },
};
