<script setup>
import { inject, onMounted, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const route = useRoute();
const heading = inject('heading');

const { fetchProjectsByKeyword, loading, pagination, projects } =
  useFetchProjects();

// ABテスト用に追加 リクエストキューとフラグ
const requestQueue = ref([]); // キュー
const isProcessing = ref(false); // 実行中のフラグ

onMounted(() => {
  heading.value = `"${route.query.keyword ?? ''}" の検索結果`;
});

watch(
  () => pagination.value.total,
  total => {
    // ABテストのため変更、totalじゃない場合は件数を出す 99999はベクトル検索用(無限スクロールのため)
    if (total !== 99999) {
      heading.value += ` ${total} 件`;
    }
  },
);

/**
 * ABテスト用に追加 キュー内のリクエストを順番に処理
 */
const processQueue = async () => {
  if (isProcessing.value || requestQueue.value.length === 0) return;

  isProcessing.value = true;
  const nextRequest = requestQueue.value.shift(); // キューから次のリクエストを取得

  try {
    await nextRequest(); // リクエストを実行
  } catch (error) {
    console.error('リクエスト処理中にエラーが発生:', error);
  } finally {
    isProcessing.value = false;
    processQueue(); // 次のリクエストを処理
  }
};

/**
 * ABテスト用に追加 キューイングの処理
 * ※ 現在の検索処理は非同期で実行され、最初に2回リクエストが発生する。
 *   このためリクエストの順序が保証されない場合、キーワード検索で表示したプロジェクトIDが正しく取得できないため
 *   そのため、リクエストの順序を制御して処理を順不同にしないように対応する。
 */
const enqueueRequest = requestFn => {
  requestQueue.value.push(requestFn); // リクエストをキューに追加
  processQueue();
};

/**
 * ABテストように追加 非同期操作をリクエストとして追加
 */
const handleFetch = async () => {
  enqueueRequest(async () => {
    await fetchProjectsByKeyword(route.query.keyword, {
      page: pagination.value.page,
      nextOffset: pagination.value.next_offset,
      excludeIds: pagination.value.excluded_ids,
    });
  });
};
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="projects-view"
    :loading
    :projects
    @fetch="handleFetch"
  >
    <template #empty>
      <MText md="large" tag="p"
        >条件に一致するプロジェクトは見つかりませんでした</MText
      >
    </template>
  </ViewBase>
</template>
