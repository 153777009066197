<script setup>
import { computed } from 'vue';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectItem from '@/components/common/ProjectItem';
import SectionHeader from '@/components/home/SectionHeader';

defineProps({
  displayNumber: {
    type: [String, Number],
    required: true,
  },
  displayRows: {
    type: [String, Number],
    default: 4,
  },
  label: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  projects: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
  },
});

const { sm, xs } = useDisplay();

const width = computed(() => {
  if (xs.value) return '160px';
  if (sm.value) return '248px';
  return '304px';
});
</script>

<template>
  <section v-if="loading || projects?.length" :class="`${label}-section`">
    <SectionHeader :label :title>
      <slot v-if="$slots[`title.append`]" name="title.append" />
    </SectionHeader>
    <ul
      v-if="projects?.length || loading"
      :class="[
        'project-list grid gap-x-2 gap-y-8 -mx-3 sm:gap-0 sm:-m-5 sm:px-2',
        { trimmed: !xs },
      ]"
    >
      <slot name="projects" :projects>
        <li v-for="(project, key) in projects" :key class="sm:my-5 sm:mx-3">
          <slot name="project" :project>
            <ProjectItem :index="key" :project :tracking-id="label">
              <template #[`thumbnail.append`]>
                <slot name="project.thumbnail.append" :project />
              </template>
              <template #subtext>
                <slot name="project.subtext" :project />
              </template>
              <template #status>
                <slot name="project.status" :project />
              </template>
            </ProjectItem>
          </slot>
        </li>
      </slot>
      <template v-if="loading">
        <slot name="loaders" :amount="Number(displayNumber)">
          <li v-for="key in Number(displayNumber)" :key class="sm:my-5 sm:mx-3">
            <slot name="loading" :order="key">
              <ProjectItem loading>
                <template #[`loader.subtext`]>
                  <slot name="project.loader.subtext" />
                </template>
                <template #[`loader.status`]>
                  <slot name="project.loader.status" />
                </template>
              </ProjectItem>
            </slot>
          </li>
        </slot>
      </template>
    </ul>
    <slot />
  </section>
</template>

<style scoped>
.project-list {
  grid-template-columns: repeat(auto-fill, minmax(v-bind(width), 1fr));
}

.project-list.trimmed {
  grid-auto-rows: 0;
  grid-template-rows: repeat(v-bind(displayRows), auto);
  overflow: hidden;
}
</style>
