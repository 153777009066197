import Cookies from 'js-cookie';

export default {
  /**
   * 気になるしたプロジェクトのIDを配列で返却する
   *
   * @returns {number[]}
   */
  listIds() {
    const cookieValue = Cookies.get('is_good');
    if (!cookieValue) return [];

    return cookieValue.split(',').map(Number).filter(Number.isInteger);
  },
};
