import { reactive, ref } from 'vue';
import useRandomPicker from '@/composables/randomPicker';
import useGaEvent from '@/composables/common/gaEvent';
import useLikedProjectIds from '@/composables/common/likedProjectIds';
import useSnackbar from '@/composables/common/snackbar';
import useTag from '@/composables/common/tag';
import useMyTag from '@/composables/favorite/myTag';
import {
  KIND_ONGOING,
  KIND_IN_STORE,
  PER_PAGE,
  SORT_POPULAR,
  SORT_OPTION_KEY_POPULAR,
} from '@/consts/discover';
import { DEFAULT_ERROR_MESSAGE } from '@/consts/error';
import GA_EVENTS from '@/consts/gaEvents';
import {
  HISTORY_DISPLAY_NUMBER,
  PROJECT_DISPLAY_NUMBER,
  RANKING_DISPLAY_NUMBER,
} from '@/consts/home';
import RANKING_CATEGORIES from '@/consts/ranking';
import meApi from '@/modules/api/php/me';
import api from '@/modules/api/v2/projects';
import rankApi from '@/modules/api/v2/rankings';
import returnApi from '@/modules/api/v2/returns';
import searchApi from '@/modules/api/php/projects';
import remindApi from '@/modules/api/php/top/projectend';
import recommendApi from '@/modules/api/php/recommended';
import likedProjects from '@/modules/likedProjects';
import loggedInStatus from '@/modules/isLoggedinStatus';
import watchedProjects from '@/modules/watchedProjects';

export default function useFetchProjects() {
  const filter = reactive({
    isOngoing: false,
    isInStore: false,
    sort: '',
  });
  const loading = ref(true);
  const pagination = ref({});
  const projects = ref([]);
  const userId = ref();

  const { likedIds, loadLikedProjectIds } = useLikedProjectIds();
  const { isLoaded: isLoadedMyTags, myTags } = useMyTag();
  const { randomPick } = useRandomPicker();
  const { showError } = useSnackbar();
  const { fetchLayerTags, genreTags, secondTags, thirdTags, topTags } =
    useTag();
  const { sendViewContentListEvent } = useGaEvent();

  /**
   * @param {string} id GAイベントとして送信するID
   * @param {string} name GAイベントとして送信する名前
   * @param {() => Promise<Object>} action fetch関数
   */
  const fn = async (id, name, action) => {
    loading.value = true;
    try {
      const data = await action();

      pagination.value = data?.pagination || {};

      if (data?.pagination?.page > 1) {
        // ページが 1 より大きい場合、既存のプロジェクトと統合し重複を排除
        const allProjects = [...projects.value, ...data.projects];
        const uniqueProjects = Array.from(
          new Map(allProjects.map(project => [project.id, project])).values(),
        );
        projects.value = uniqueProjects;
      } else {
        // ページが 1 以下の場合 data.projects を重複を排除した上で設定
        const uniqueProjects = Array.from(
          new Map(
            (data?.projects || []).map(project => [project.id, project]),
          ).values(),
        );
        projects.value = uniqueProjects;
      }

      if (!data?.projects?.length) return;
      sendViewContentListEvent(
        `${id}${data?.pagination?.page ? `_${data.pagination.page}` : '_1'}`,
        name,
      );
    } catch (e) {
      pagination.value = {};
      projects.value = [];
      showError({ message: DEFAULT_ERROR_MESSAGE });
    } finally {
      loading.value = false;
    }
  };

  // データがない場合のレスポンス
  const noData = { projects: [] };

  // ABテストのため nextOffset、excludeIds、abSearchを追加
  /** キーワード検索によってプロジェクトを取得する */
  const fetchProjectsByKeyword = async (
    keyword,
    {
      page = 1,
      perPage = PER_PAGE,
      nextOffset = 0,
      excludeIds = '',
      abSearch = 'a',
    } = {},
  ) => {
    await fn(
      GA_EVENTS.FETCH_PROJECTS_BY_KEYWORD.ID,
      GA_EVENTS.FETCH_PROJECTS_BY_KEYWORD.NAME,
      async () => {
        const { data } = await searchApi.fetchProjectsByKeyword(keyword, {
          page,
          perPage,
          nextOffset,
          excludeIds,
          abSearch,
        });
        return data;
      },
    );
  };

  /** すべてのプロジェクトを取得する */
  const fetchAllProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_ALL_PROJECTS.ID,
      GA_EVENTS.FETCH_ALL_PROJECTS.NAME,
      async () => {
        const props = {
          page,
          per_page: perPage,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchProjects(props);
        return data;
      },
    );
  };

  /** プロジェクトの閲覧履歴を取得する */
  const fetchWatchedProjects = async ({
    page = 1,
    perPage = HISTORY_DISPLAY_NUMBER,
    withUser = false,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_WATCHED_PROJECTS.ID,
      GA_EVENTS.FETCH_WATCHED_PROJECTS.NAME,
      async () => {
        const ids = watchedProjects.listIds();
        if (!ids.length) return noData;

        const { data } = await api.fetchProjects({
          ids: ids.join(),
          page,
          per_page: perPage,
          with_user: withUser,
        });
        return data;
      },
    );
  };

  /** 気になるしたプロジェクトを取得する */
  const fetchLikedProjects = async ({
    offsetSub = 0,
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_LIKED_PROJECTS.ID,
      GA_EVENTS.FETCH_LIKED_PROJECTS.NAME,
      async () => {
        const userLoggedIn = loggedInStatus.isUserLoggedin();
        if (!userLoggedIn) {
          const res = await meApi.fetchIsLogin();
          if (!res.data?.is_logined) {
            const ids = likedProjects.listIds();
            if (!ids.length) return noData;

            const { data } = await api.fetchProjects({
              ids: ids.join(),
              with_user: true,
            });
            data.pagination = {
              page,
              per_page: perPage,
              total: ids.length,
            };
            return data;
          }
        }

        const { data } = await api.fetchLikedProjects({
          offset_sub: offsetSub,
          page,
          per_page: perPage,
          with_user: true,
        });

        /** 気になるリストの取得完了を待機 */
        await loadLikedProjectIds();
        const ids = data.projects.map(project => project.id);

        // 取得したプロジェクトが Redis から取得したデータに含まれていない場合は追加する
        if (ids.some(id => !likedIds.value.includes(id))) {
          likedIds.value = [
            ...likedIds.value,
            ...ids.filter(id => !likedIds.value.includes(id)),
          ];
        }

        return data;
      },
    );
  };

  /** Myタグに紐づいたプロジェクトを取得する */
  const fetchMyTagProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_MY_TAG_PROJECTS.ID,
      GA_EVENTS.FETCH_MY_TAG_PROJECTS.NAME,
      async () => {
        /** Myタグの取得完了を待機 */
        const waitForLoaded = () =>
          new Promise(resolve => {
            if (isLoadedMyTags.value) resolve(true);
            else setTimeout(() => resolve(waitForLoaded()), 100);
          });

        if (await waitForLoaded()) {
          // Myタグがない場合は空のデータを返す
          if (!myTags.value.length) return noData;

          const { data } = await api.fetchProjectsByTagIds({
            kinds: KIND_ONGOING,
            page,
            per_page: perPage,
            tag_ids: myTags.value.map(tag => tag.id),
            with_user: true,
          });
          return data;
        }

        // エラーハンドリング用
        return undefined;
      },
    );
  };

  /** ユーザーに応じた終了間近のプロジェクトを取得する */
  const fetchRemindedProjects = async () => {
    await fn(
      GA_EVENTS.FETCH_REMINDED_PROJECTS.ID,
      GA_EVENTS.FETCH_REMINDED_PROJECTS.NAME,
      async () => {
        const userLoggedIn = loggedInStatus.isUserLoggedin();
        if (!userLoggedIn) return undefined;

        const { data } = await remindApi.fetchProjectsEnd();
        return data;
      },
    );
  };

  /** おすすめプロジェクトを取得する */
  const fetchRecommendedProjects = async ({ exclude, perPage = 10 } = {}) => {
    await fn(
      GA_EVENTS.FETCH_RECOMMENDED_PROJECTS.ID,
      GA_EVENTS.FETCH_RECOMMENDED_PROJECTS.NAME,
      async () => {
        const viewedProjectIds = watchedProjects.listIds().join();
        const { data } = await recommendApi.fetchRecommended({
          nItems: perPage,
          projectId: exclude,
          viewedProjectIds,
          withUser: true,
        });
        userId.value = data?.recommend_user_id;
        return data;
      },
    );
  };

  /** ピックアップ中のプロジェクトを取得する */
  const fetchPickupProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_PICKUP_PROJECTS.ID,
      GA_EVENTS.FETCH_PICKUP_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchPickup({
          page,
          per_page: perPage,
          sort: 'random',
          with_user: true,
        });
        return data;
      },
    );
  };

  /**
   * ランクインしたプロジェクトを取得する
   *  @param {string} tagGroup */
  const fetchRankedProjects = async ({
    tagGroup = RANKING_CATEGORIES[0].value,
    perPage = RANKING_DISPLAY_NUMBER,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_RANKED_PROJECTS.ID,
      GA_EVENTS.FETCH_RANKED_PROJECTS.NAME,
      async () => {
        const { data } = await rankApi.fetchRanking({
          limit: perPage,
          tag_group: tagGroup,
          with_returns: true,
          with_user: true,
        });
        // 共通処理でdata.projectsを操作するので新しいオブジェクトにする
        return {
          projects: data?.rankings?.length
            ? data.rankings.reduce((acc, cur) => {
                const { project } = cur;
                project.rank = cur.rank;
                acc.push(project);
                return acc;
              }, [])
            : [],
        };
      },
    );
  };

  /** 新着のプロジェクトを取得する */
  const fetchNewProjects = async ({
    page = 1,
    perPage = PER_PAGE,
    random = 0,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_NEW_PROJECTS.ID,
      GA_EVENTS.FETCH_NEW_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchNew({
          page,
          per_page: perPage,
          with_user: true,
        });
        if (random) {
          data.projects = data?.projects?.length
            ? randomPick(data.projects, random)
            : [];
        }
        return data;
      },
    );
  };

  /** もうすぐ開始するプロジェクトを取得する */
  const fetchComingSoonProjects = async ({
    page = 1,
    perPage = PER_PAGE,
    random = 0,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_COMING_SOON_PROJECTS.ID,
      GA_EVENTS.FETCH_COMING_SOON_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchComingSoon({
          page,
          per_page: perPage,
          with_user: true,
        });
        if (random) data.projects = randomPick(data.projects, random);
        return data;
      },
    );
  };

  /** 人気のプロジェクトを取得する */
  const fetchPopularProjects = async () => {
    await fn(
      GA_EVENTS.FETCH_POPULAR_PROJECTS.ID,
      GA_EVENTS.FETCH_POPULAR_PROJECTS.NAME,
      async () => {
        const { data } = await returnApi.fetchPopular({
          per_page: PROJECT_DISPLAY_NUMBER,
          with_returns: true,
          with_user: true,
        });
        data.projects = data?.returns?.length
          ? data.returns.reduce((acc, cur) => {
              // プロジェクトの重複を防ぐ
              if (!acc.some(project => project.id === cur.project.id)) {
                acc.push(cur.project);
              }
              return acc;
            }, [])
          : [];
        return data;
      },
    );
  };

  /**
   * 特定のタグがついたプロジェクトを取得する
   * @param {number|number[]} tagIds 対象のタグIDまたはその配列
   * @param {{
   * isRecommended?: boolean;
   * page?: number;
   * perPage?: number;
   * excludedProjectId?: number;
   * random?: number;
   * }} [opts={}]
   */
  const fetchProjectsByTagIds = async (
    tagIds,
    {
      excludedProjectId = 0,
      isRecommended = false,
      page = 1,
      perPage = PER_PAGE,
      random = 0,
    } = {},
  ) => {
    await fn(
      `${GA_EVENTS.FETCH_PROJECTS_BY_TAG_IDS.ID}_${tagIds}`,
      GA_EVENTS.FETCH_PROJECTS_BY_TAG_IDS.NAME,
      async () => {
        const props = {
          is_recommended: isRecommended,
          page,
          per_page: perPage,
          tag_ids: tagIds,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchProjectsByTagIds(props);

        // 自身のプロジェクトを除外
        if (excludedProjectId) {
          data.projects = data.projects.filter(
            project => project.id !== excludedProjectId,
          );
        }

        if (random) {
          data.projects = data?.projects?.length
            ? randomPick(data.projects, random)
            : [];
        }

        return data;
      },
    );
  };

  /** もうすぐ終了するプロジェクトを取得する */
  const fetchEndingSoonProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_ENDING_SOON_PROJECTS.ID,
      GA_EVENTS.FETCH_ENDING_SOON_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchEndingSoon({
          page,
          per_page: perPage,
          with_user: true,
        });
        return data;
      },
    );
  };

  /** 推奨実行者のプロジェクトを取得する */
  const fetchSelectedOwnersProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_SELECTED_OWNERS_PROJECTS.ID,
      GA_EVENTS.FETCH_SELECTED_OWNERS_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchSelected({
          page,
          per_page: perPage,
          with_user: true,
        });
        return data;
      },
    );
  };

  /** ふるさと納税型のプロジェクトを取得する */
  const fetchGovernmentProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_GOVERNMENT_PROJECTS.ID,
      GA_EVENTS.FETCH_GOVERNMENT_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchGovernment({
          page,
          per_page: perPage,
          with_user: true,
        });
        return data;
      },
    );
  };

  /** 応援購入総額が多い順にプロジェクトを取得する */
  const fetchMostFundedProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_MOST_FUNDED_PROJECTS.ID,
      GA_EVENTS.FETCH_MOST_FUNDED_PROJECTS.NAME,
      async () => {
        const props = { page, per_page: perPage, with_user: true };

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchMostFunded(props);
        data.projects = data.projects.map((project, index) => ({
          ...project,
          rank:
            ((data?.pagination?.page || 0) - 1) *
              (data?.pagination?.per_page || 0) +
            index +
            1,
        }));
        return data;
      },
    );
  };

  /**
   * 特定のカテゴリーがついたプロジェクトを取得する
   *  @param {string} categorySlug 対象のカテゴリースラッグ */
  const fetchProjectsByCategorySlug = async (
    categorySlug,
    { page = 1, perPage = PER_PAGE } = {},
  ) => {
    await fn(
      `${GA_EVENTS.FETCH_PROJECTS_BY_CATEGORY_SLUG.ID}_${categorySlug}`,
      GA_EVENTS.FETCH_PROJECTS_BY_CATEGORY_SLUG.NAME,
      async () => {
        const props = {
          category_code: categorySlug,
          page,
          per_page: perPage,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) {
          props.is_ongoing = true;
          kinds.push(KIND_ONGOING);
        }
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) {
          props.kinds = kinds.join();
          props.type = 'all';
        }

        const { data } = await api.fetchProjectsByCategoryCode(props);
        return data;
      },
    );
  };

  /**
   * 特定の地域のプロジェクトを取得する
   *  @param {number[]} locationIds 対象の地域IDの配列  */
  const fetchProjectsByLocationIds = async (
    locationIds,
    { page = 1, perPage = PER_PAGE } = {},
  ) => {
    await fn(
      `${GA_EVENTS.FETCH_PROJECTS_BY_LOCATION_IDS.ID}_${locationIds}`,
      GA_EVENTS.FETCH_PROJECTS_BY_LOCATION_IDS.NAME,
      async () => {
        const props = {
          location_ids: locationIds,
          page,
          per_page: perPage,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchProjectsByLocationIds(props);
        return data;
      },
    );
  };

  /** タグを基に類似プロジェクトを取得する */
  const fetchSimilarProjects = async (tagIds, projectId = 0) => {
    await fn(
      GA_EVENTS.FETCH_SIMILAR_PROJECTS.ID,
      GA_EVENTS.FETCH_SIMILAR_PROJECTS.NAME,
      async () => {
        // タグがない場合は空のデータを返す
        if (!tagIds.length) return noData;

        /** タグレイヤーの取得完了を待機 */
        await fetchLayerTags();

        const tagOrder = [
          thirdTags.value,
          secondTags.value,
          topTags.value,
          genreTags.value,
        ];

        // タグを階層ごとにグループ化して並び替え
        const groupedTags = tagOrder.map(tags =>
          tagIds.filter(tagId => tags.some(tag => tag.id === tagId)),
        );

        // タグごとにプロジェクトを取得
        const projectPromises = groupedTags.map(levelTags =>
          levelTags.length > 0
            ? api.fetchProjectsByTagIds({
                kinds: KIND_ONGOING,
                page: 1,
                per_page: 5 * levelTags.length,
                sort: SORT_POPULAR,
                tag_ids: levelTags,
                with_user: true,
              })
            : [],
        );

        const projectResults = await Promise.all(projectPromises);

        const allProjects = projectResults.flatMap(
          result => result?.data?.projects || [],
        );

        // 自身のプロジェクトを除外して返す
        return { projects: allProjects.filter(p => p.id !== projectId) };
      },
    );
  };

  return {
    fetchAllProjects,
    fetchComingSoonProjects,
    fetchEndingSoonProjects,
    fetchGovernmentProjects,
    fetchLikedProjects,
    fetchMostFundedProjects,
    fetchMyTagProjects,
    fetchNewProjects,
    fetchPickupProjects,
    fetchPopularProjects,
    fetchProjectsByCategorySlug,
    fetchProjectsByKeyword,
    fetchProjectsByLocationIds,
    fetchProjectsByTagIds,
    fetchRankedProjects,
    fetchRecommendedProjects,
    fetchRemindedProjects,
    fetchSelectedOwnersProjects,
    fetchSimilarProjects,
    fetchWatchedProjects,
    fn, // for unit test
    filter,
    loading,
    pagination,
    projects,
    userId,
  };
}
