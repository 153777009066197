<script setup>
import { onBeforeMount } from 'vue';
import { MDivider, MSnackbar, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import Navigation from '@/apps/Navigation';
import useSnackbar from '@/composables/common/snackbar';
import api from '@/modules/api/php/me';
import store from '@/store';

const { mdAndUp } = useDisplay();
const { action, message, snackbar, props } = useSnackbar();

onBeforeMount(async () => {
  const { data } = await api.fetchIsLogin();
  const isLoggedIn = data?.is_logined ?? false;
  store.setIsLogin(isLoggedIn);
});
</script>

<template>
  <main class="flex gap-2">
    <Navigation v-if="mdAndUp" class="flex-none" />
    <article class="grow overflow-hidden p-6 pb-16 md:pt-8 md:pb-24">
      <RouterView />
    </article>
  </main>
  <MDivider />
  <MSnackbar v-model="snackbar" v-bind="props" @action="action">
    {{ message }}
  </MSnackbar>
</template>

<style lang="scss">
html {
  font-size: 100%;
}

#header {
  position: sticky;
  top: 0;
  z-index: 10000;

  .cb-header {
    position: static;
  }
}
</style>

<style scoped>
.navigation {
  height: calc(100vh - 65px);
  position: sticky;
  top: 65px;
  width: 224px;
}
</style>
