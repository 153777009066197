<script setup>
import { computed, onBeforeMount, onMounted, toValue } from 'vue';
import { MCol, MRow } from '@ca-crowdfunding/makuake-ui-n';
import FriendInviteTicketAcquireModal from '@/components/friendinvite/FriendInviteTicketAcquireModal';
import ActivityReportSection from '@/components/home/ActivityReportSection';
import ApplicationSection from '@/components/home/ApplicationSection';
import BannerArea from '@/components/home/BannerArea';
import BlogSection from '@/components/home/BlogSection';
import CatchCopy from '@/components/home/CatchCopy';
import CategorySection from '@/components/home/CategorySection';
import ComingSoonSection from '@/components/home/ComingSoonSection';
import EventSection from '@/components/home/EventSection';
import HeroArea from '@/components/home/HeroArea';
import HistorySection from '@/components/home/HistorySection';
import IntroductionSection from '@/components/home/IntroductionSection';
import NewArrivalsSection from '@/components/home/NewArrivalsSection';
import PickupSection from '@/components/home/PickupSection';
import RankingSection from '@/components/home/RankingSection';
import RecommendedListSection from '@/components/home/RecommendedListSection';
import RecommendedSection from '@/components/home/RecommendedSection';
import RemindedSection from '@/components/home/RemindedSection';
import ServiceSection from '@/components/home/ServiceSection';
import TagSection from '@/components/home/TagSection';
import TaggedSection from '@/components/home/TaggedSection';
import useDevice from '@/composables/common/device';
import useTag from '@/composables/common/tag';
import { TAGS } from '@/consts/home/tag';

const { app, loadDevice } = useDevice();
const { recommendedTags, fetchRecommendedTags } = useTag();

onBeforeMount(async () => {
  await loadDevice();
  if (app.value) return;

  const { braze } = window;

  if (braze) {
    braze.logCustomEvent('pageview_top', { timestamp: new Date() });
  }
});

onMounted(async () => fetchRecommendedTags());

const tags = computed(() => [
  ...toValue(recommendedTags).map(tag => ({
    ...tag,
    isRecommended: true,
  })),
  // おすすめのタグとの重複は除外
  ...Object.values(TAGS).filter(
    tag =>
      !toValue(recommendedTags).some(
        recommendedTag => recommendedTag.id === tag.id,
      ),
  ),
]);
</script>

<template>
  <div class="flex flex-col gap-10 md:mt-2">
    <CatchCopy />
    <HeroArea />
    <BannerArea />
    <CategorySection />
    <HistorySection />
    <RemindedSection />
    <RecommendedSection />
    <!-- TODO: PX-2883 ABテスト終了後に不要箇所削除 ここから -->
    <RecommendedListSection class="hidden sp-b" display-number="4" />
    <RecommendedListSection class="hidden sp-c" display-number="8" />
    <RecommendedListSection class="hidden sp-d" display-number="16" />
    <RecommendedListSection
      class="hidden pc-b"
      display-number="12"
      display-rows="2"
    />
    <RecommendedListSection
      class="hidden pc-c"
      display-number="20"
      display-rows="4"
    />
    <!-- TODO: PX-2883 ここまで -->
    <PickupSection />
    <RankingSection />
    <NewArrivalsSection />
    <ComingSoonSection />
    <!-- TODO: PX-2855 ABテスト終了後に不要箇所削除 ここから -->
    <TaggedSection v-for="(tag, key) in TAGS" :key :tag />
    <div class="tagged-section-wrapper contents">
      <TaggedSection
        v-for="(tag, key) in tags"
        :key
        class="hidden px-2855-b"
        :is-recommended="tag.isRecommended"
        :tag
      />
    </div>
    <!-- TODO: PX-2855 ここまで -->
    <ApplicationSection />
    <TagSection />
    <MRow class="max-w-screen-lg" gap="10">
      <MCol cols="12" md="6"><ActivityReportSection /></MCol>
      <MCol cols="12" md="6"><BlogSection /></MCol>
    </MRow>
    <EventSection />
    <IntroductionSection />
    <ServiceSection />
  </div>
  <FriendInviteTicketAcquireModal />
</template>

<style lang="scss" scoped>
.tagged-section-wrapper > *:nth-child(n + 7) {
  display: none;
}
</style>
