import { computed, onUnmounted, ref, watchEffect } from 'vue';

/**
 * スクロールコンテナを管理するコンポーザブル
 * @param {import('vue').ComputedRef<HTMLElement>} container スクロール可能なDOM要素
 */
export default function useScrollContainer(container) {
  const currentPosition = ref(0);
  const offsetWidth = ref(0);
  const scrollAmount = ref(0);
  const scrollGutter = 16;
  const isScrollable = computed(() =>
    container.value ? scrollAmount.value > offsetWidth.value : false,
  );
  const isScrolling = ref(false);

  const isLeftScrollDisabled = computed(() => {
    if (!container.value || isScrolling.value) return true;
    return currentPosition.value <= scrollGutter;
  });

  const isRightScrollDisabled = computed(() => {
    if (!container.value || isScrolling.value) return true;
    return (
      currentPosition.value + offsetWidth.value + scrollGutter >=
      scrollAmount.value
    );
  });

  const scrollTo = (direction = 1) => {
    if (!container.value) return;

    isScrolling.value = true;
    container.value.scrollBy({
      left: offsetWidth.value * direction,
      behavior: 'smooth',
    });
  };

  const scrollToLeft = () => scrollTo(-1);
  const scrollToRight = () => scrollTo();

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const setValues = debounce(() => {
    if (!container.value) return;

    currentPosition.value = container.value.scrollLeft;
    offsetWidth.value = container.value.offsetWidth;
    scrollAmount.value = container.value.scrollWidth;
    isScrolling.value = false;
  }, 100);

  const initialize = () => {
    if (!container.value) return;

    container.value.addEventListener('scroll', setValues);
    setValues();
  };

  // MutationObserver の設定
  const callback = mutationsList => {
    if (mutationsList.some(mutation => mutation.type === 'childList')) {
      // 子要素が変更された場合に initialize を呼び出す
      initialize();
    }
  };

  const observer = new MutationObserver(callback);

  watchEffect(() => {
    if (container.value) {
      observer.observe(container.value, { childList: true });
    }
  });

  onUnmounted(() => {
    if (container.value) {
      container.value.removeEventListener('scroll', setValues);
      observer.disconnect();
    }
  });

  return {
    currentPosition,
    isLeftScrollDisabled,
    isRightScrollDisabled,
    isScrollable,
    isScrolling,
    scrollToLeft,
    scrollToRight,
  };
}
