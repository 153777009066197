import { createClient } from '@/modules/api/createClient';

/**
 * @typedef {Object} FetchRecommendedTagsParams
 * @property {Array<string|number>} project_ids
 */

/**
 * 閲覧履歴を基におすすめのタグを取得する
 * @param {FetchRecommendedTagsParams} params
 */
const fetchRecommendedTags = params =>
  createClient().get('/v2/recommended-tags', { params });

export default {
  fetchRecommendedTags,
};
