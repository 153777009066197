import client from '@/modules/api/createClient';

/**
 * @typedef {{
 * projects: {
 *   id: number;
 *   collected_money: number;
 *   collected_supporter: number;
 *   expiration_date: number;
 *   time_left_label: string;
 *   percent: number;
 *   image_url: string;
 *   title: string;
 *   url: string;
 *   is_new: boolean;
 *   is_store_opening: boolean;
 *   has_target_money: boolean;
 *   has_expiration: boolean;
 *   is_accepting_support: boolean;
 *   hide_collected_money: boolean;
 *   user: {
 *     id: number;
 *     name: string;
 *     image_url: string;
 *     is_selected_user: boolean;
 *   };
 * }[];
 * pagination: {
 *   page: number;
 *   per_page: number;
 *   total: number;
 * };
 * }} FetchProjectsResponse
 */

/**
 * @param {
 * type: 'keyword' | 'maybe-missing';
 * keyword?: string;
 * page?: number;
 * per_page?: number;
 * }
 * @returns {Promise<import('axios').AxiosResponse<FetchProjectsResponse>>}
 */
// VWOからwindow.vwo_ab_searchを取得、vwo_ab_searchを取得に値が設定されている場合はBパターン
const fetch = async params => {
  // 検索エンジンABテストのため変更
  let abSearch = 'a';

  // 0.5秒間 10ms間隔で監視(VWOのモジュールのほうがこのモジュールより後に読み込まれるため、タイムラグを考慮する)
  const pollForValue = async () =>
    new Promise(resolve => {
      const start = Date.now();
      const interval = setInterval(() => {
        if (typeof window.vwo_ab_search !== 'undefined') {
          clearInterval(interval);
          resolve(true);
        } else if (Date.now() - start >= 500) {
          clearInterval(interval);
          resolve(false);
        }
      }, 10);
    });

  // 値が変わった場合は Bパターン
  if (await pollForValue()) {
    abSearch = 'b';
  }

  // 現在のAPI呼び出し
  return client.phpApiClient.get('/projects', {
    params: { ...params, abSearch },
  });
};

/**
 * `keyword` に関連するプロジェクトを取得する
 *
 * @param {string} keyword
 * @param {{
 * page?: number;
 * perPage?: number;
 * }} options
 */
// ABテストのため nextOffset、excludeIds、abSearchを追加
const fetchProjectsByKeyword = async (
  keyword,
  { page, perPage, nextOffset, excludeIds, abSearch } = {},
) =>
  fetch({
    type: 'keyword',
    keyword,
    page,
    per_page: perPage,
    nextOffset,
    excludeIds,
    abSearch,
  });

export default {
  fetchProjectsByKeyword,
};
