<script setup>
import { computed } from 'vue';
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useIntersection from '@/composables/common/intersection';
import useFetchProjects from '@/composables/common/fetchProjects';
import { PROJECT_DISPLAY_NUMBER, TAGS } from '@/consts/home';
import { SORT_OPTION_KEY_POPULAR } from '@/consts/discover';

const maxProjectCount = 40;

const props = defineProps({
  isRecommended: { type: Boolean, default: false },
  tag: { type: Object, required: true },
});

const { setupIntersection } = useIntersection();
const { fetchProjectsByTagIds, filter, loading, projects } = useFetchProjects();

const label = computed(() =>
  props.isRecommended
    ? `tag_${props.tag.id}`
    : (
        Object.keys(TAGS).find(TAG => TAGS[TAG].id === props.tag.id) || ''
      ).toLowerCase(),
);

const href = computed(() => {
  const queryParams = {
    ongoing: true,
    in_store: false,
    sort: SORT_OPTION_KEY_POPULAR,
  };
  const queryStr = props.isRecommended
    ? `?${new URLSearchParams(queryParams).toString()}`
    : '';
  return `/discover/tags/${props.tag.id}${queryStr}`;
});

setupIntersection(() => {
  let perPage = PROJECT_DISPLAY_NUMBER;
  let random;
  if (props.isRecommended) {
    perPage = maxProjectCount;
    random = PROJECT_DISPLAY_NUMBER;
    filter.isOngoing = true;
    filter.sort = SORT_OPTION_KEY_POPULAR;
  }

  fetchProjectsByTagIds(props.tag.id, {
    isRecommended: props.isRecommended,
    perPage,
    random,
  });
});
</script>

<template>
  <ProjectSectionBase
    class="tagged-section"
    :href
    :projects
    :label
    :loading
    :title="tag.title"
  />
</template>
